import forbiddenStatuses from './forbiddenStatuses';
import ordersScheduled from './ordersScheduled';
import customOrderDecisionRules from './customOrderDecisionRules';
import vendorAutoSchedule from './vendorAutoSchedule';
import customPurchaseOrderForm from './customPurchaseOrderForm';
import customPurchaseOrderGrid from './customPurchaseOrderGrid';
import customPurchaseOrderOverview from './customPurchaseOrderOverview';
import customVendorProductGrid from './customVendorProductGrid';
import customRma from './customRma';

export default {
    forbiddenStatuses,
    ordersScheduled,
    customOrderDecisionRules,
	  vendorAutoSchedule,
    customPurchaseOrderForm,
    customPurchaseOrderGrid,
    customPurchaseOrderOverview,
    customVendorProductGrid,
    customRma,
};
